import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { fetchmedia } from "../Reducers/actions";
import { message } from "../../../Reducers/loading/actions";
import { useParams } from "react-router-dom";
import moment from "moment";
function MediaItem({ fetchmedia, match, media }) {
  const [loading, setLoading] = useState(false);
  const params = useParams();

  useEffect(() => {
    fetchmedia(params.id).then((res) => {
      setLoading(true);
    });
  });

  return (
    <div className="content-page media">
      <div className="box box-default settings">
        <div className="box-header with-border">
          <h3 className="box-title"> Mediabestand</h3>
        </div>

        <div className="box-body">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12">
                <div className="box box-info">
                  <div className="box-header with-border">
                    <h3 className="box-title">Media</h3>
                  </div>
                  {loading && (
                    <div className="box-body">
                      <div className="row">
                        <div className="col-lg-7">
                        <div className="box p-4">
                          <img src={`https://backoffice.wwwappz.nl/${media.thumbnail ? media.thumbnail.small : media.image}`} className="img-responsive custom-img" />
                          </div>
                        </div>

                        <div className="col-lg-5">
                          <div className="box">
                            <div className="box-header with-border">
                              <h3 className="box-title">Instellingen</h3>
                            </div>
                            <div className="box-body">
                              <h6>Informatie</h6>
                              <hr />
                              <div className="row">
                                <div className="col-6">
                                  <p>Gemaakt</p>
                                </div>
                                <div className="col-6 text-end">
                                  <p>{moment(media.created).format("DD-MM-YYYY HH:mm")}</p>
                                </div>
                                <div className="col-6">
                                  <p>Geupdate</p>
                                </div>
                                <div className="col-6 text-end">
                                  <p>{moment(media.modified).format("DD-MM-YYYY HH:mm")}</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return { media: state.cms.media.item };
}

export default connect(mapStateToProps, {
  fetchmedia,
  message,
})(MediaItem);
