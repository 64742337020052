import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import Daterange from "../../Components/Parts/Daterange";
import moment from "moment";
import {
  Periode,
  ExportExcel,
  ExportWord,
} from "./Reducers/events/actions";
class EventPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      errors: {},
      query: [],
      zoeken: true,
    };
  }
  componentWillMount() {}

  findevents() {
    this.props.Periode(this.state.query);
  }

  export2excel() {
    this.props.ExportExcel(this.state.query);
  }

  export2word() {
    this.props.ExportWord(this.state.query);
  }

  onChangeDate = (value) => {
    if (value.startDate && value.endDate) {
      this.setState({ zoeken: false });
      this.setState({ query: value });
    } else {
      this.setState({ zoeken: true });
    }
  };
  einddatum(datum) {
    if (datum) {
      return moment(datum).format("DD-MM-YYYY");
    }
  }

  render() {
    const { events } = this.props;
    return (
      <div className="box box-default">
        <div className="box-header with-border">
          <h3 className="box-title">Evenementen</h3>
          <div className="box-tools pull-right">
            <Link
              to="/items/add"
              type="button"
              className="btn-primary btn btn-box-too btn-sm"
            >
              <i className="fa fa-plus"></i>
              &nbsp; Evenement Toevoegen
            </Link>
          </div>
        </div>
        <div className="box-body">
          <h3 className="box-title">Welke periode</h3>
          <div className="box-body">
            <Daterange onChangeDate={this.onChangeDate.bind(this)} />
            <button
              onClick={(e) => this.findevents()}
              type="button"
              disabled={this.state.zoeken}
              className="btn-primary btn btn-box-too btn-sm"
            >
              Zoek de evenementen
            </button>{" "}
            <span>&nbsp;</span>
            <button
              onClick={(e) => this.export2excel()}
              type="button"
              disabled={this.state.zoeken}
              className="btn-danger btn btn-box-too btn-sm"
            >
              Exporteer naar Excel
            </button>{" "}
            <span>&nbsp;</span>
            <button
              onClick={(e) => this.export2word()}
              type="button"
              disabled={this.state.zoeken}
              className="btn-warning btn btn-box-too btn-sm"
            >
              Exporteer naar Word
            </button>
          </div>

          <div className="box-header with-border">
            <h3 className="box-title">Evenementen</h3>
          </div>
          <div className="box-body">
            <div className="box-body table-responsive no-padding">
              <table className="table table-hover">
                <tbody>
                  <tr>
                    <th>Datum</th>
                    <th>Tot</th>
                    <th>Titel</th>
                    <th>Beschrijving</th>
                  </tr>
                  {events.map((app) => (
                    <tr key={app._id}>
                      <td>{moment(app.event.datum).format("DD-MM-YYYY")}</td>
                      <td>{this.einddatum(app.event.einddatum)}</td>
                      <td>{app.what.title.value}</td>
                      <td>{app.what.shortdescription.value.substr(0, 30)}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return { errors: state.auth.error, events: state.events.events };
}
export default connect(mapStateToProps, { Periode, ExportExcel, ExportWord })(
  EventPage
);
