import React from "react";
import { Field, Form } from "react-final-form";
import { connect } from "react-redux";
import { required } from "redux-form-validators";
import { FieldArray } from "react-final-form-arrays";
import arrayMutators from "final-form-arrays";
import Wysiwyg from "../../../../Components/Forms/wysiwyg";
import FooterForm from "../../../../Components/Lib/FooterForm";
import UploadFormSingle from "../../../../Components/Forms/uploadsingle";
import Autosuggest from "../../../../Components/Forms/autocomplete";
import {
  renderTextField,
  renderField,
} from "../../../../Components/Forms/renders";
import renderDaterange from "../../../../Components/Forms/daterange";
import Textinputarray from "../../../../Components/Forms/textinputarray";
import BedrijvenForm from "../../../../Components/Forms/bedrijven";
class SyncValidationForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: {},
    };
  }

  render() {
    const { onSubmit } = this.props;
    return (
      <Form
        onSubmit={onSubmit}
        mutators={{
          ...arrayMutators,
        }}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <div className="col-sm-12">
              <h4 className="info-text">
                Vul hier het nieuwe arrangementen in.
              </h4>
            </div>
            <Field
              name="titel"
              validate={required({ msg: "Titel is verplicht" })}
              type="text"
              component={renderField}
              label="Titel"
            />
                <Field
              name="subtitel"
              validate={required({ msg: "Subtitel is verplicht" })}
              type="text"
              component={renderField}
              label="Subtitel"
            />
            <Field
              name="bedrijven"
              type="input"
              component={BedrijvenForm}
              label="Kies om het item aan een locatie te koppelen"
            />
            <Field
              name="prijs"
              type="text"
              component={renderField}
              label="Vul de prijs in"
            />
               <Field
              name="prijstext"
              type="text"
              component={renderField}
              label="Vul de text in die bij de prijs hoort. (bv Pijs per persoon)"
            />
            <FieldArray
              name="pluspunten"
              component={Textinputarray}
              label="Vul de pluspunten in"
            />
            <Field
              name="beschrijving"
              type="textarea"
              validate={required({ msg: "Beschrijving is verplicht" })}
              component={Wysiwyg}
              label="Lange Omschrijving"
              className="form-control"
              normalize={(value) => value.toString("html")}
            />
            <Field
              name="datum"
              component={renderDaterange}
              label="In welke periode is het arrangement"
              mindate={true} maxdate={true}
            />
            <Field
              validate={required({ msg: "Afbeelding is verplicht" })}
              name="images"
              component={UploadFormSingle}
              label="Kies bestanden als header"
            />

            <FooterForm />
          </form>
        )}
      />
    );
  }
}

// You have to connect() to any Reducers that you wish to connect to yourself
SyncValidationForm = connect(
  (state) => ({}) // bind account loading action creator
)(SyncValidationForm);

export default SyncValidationForm;
