import React from "react";
import { Routes, Route } from "react-router-dom";
import IndexSettings from "./Settings/index";
import Talenpage from "./Settings/talen";
import Websitepage from "./Settings/website";
import IndexTypes from "./Types/index";
import IndexMedia from "./Media/index";
import MediaIndexSetting from "./Settings/media";
import ComponentNew from "./Types/Componenten/new";
import ComponentEdit from "./Types/Componenten/edit";
import TemplateNew from "./Types/Templates/new";
import TemplateEdit from "./Types/Templates/edit";
import PageIndex from "./Types/Pages/index";
import ContentIndex from "./Content/index";
import ContentItem from "./Content/item";
import OnderdeelPagina from "./Settings/onderdelen";
import "./styles.scss";
import MediaItem from "./Media/item";

const CMSRouter = () => {
  return (
    <Routes>
      <Route path="/settings" element={<IndexSettings />} />
      <Route path="/settings/talen" element={<Talenpage />} />
      <Route path="/settings/website" element={<Websitepage />} />
      <Route path="/settings/media" element={<MediaIndexSetting />} />
      <Route path="/settings/onderdelen" element={<OnderdeelPagina />} />
      <Route path="/types" element={<IndexTypes />} />
      <Route path="/types/componenten/new" element={<ComponentNew />} />
      <Route path="/types/componenten/edit/:id" element={<ComponentEdit />} />
      <Route path="/types/templates/new" element={<TemplateNew />} />
      <Route path="/types/templates/edit/:id" element={<TemplateEdit />} />
      <Route path="/media" element={<IndexMedia />} />
      <Route path="/media/:id" element={<MediaItem />} />
      <Route path="/pages" element={<PageIndex />} />
      <Route path="/content" element={<ContentIndex />} />
      <Route path="/content/:id" element={<ContentItem />} />
    </Routes>
  );
};

export default CMSRouter;
