import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { getsensorelectra, getsensorelectra_dag, getsensorelectra_maand, getsensorelectra_uur } from "../Reducers/actions";
import { fixmessage, loading } from "../../../Reducers/loading/actions";
import moment from "moment";
import LineGraph from "./Components/linegraps";
import BarGraph from "./Components/graphbar";
import { Link, useParams } from "react-router-dom";
import Tabel from "./Components/tabel";
import FilterSensor from "./Components/filter";
import KiesDate from "../Components/datumkeuze";
import Voorspellen from "../Components/voorspellen";
const SensorItemElectra = (props) => {
  const {id} = useParams();
  const [load, Setload] = useState(false);
  const [datum, Setdatum] = useState({ startdate: moment().subtract(7, "days").toDate(), enddate: new Date() });
  const [vorigperiode, Setvorigperiode] = useState({ startdate: moment().subtract(338, "hours").toDate(), enddate: moment().subtract(168, "hours").toDate() });
  const [datum_hour, Setdatum_hour] = useState({
    startdate: moment().subtract(1, "days").toDate(),
    enddate: new Date(),
    temp_id: "eui-a84041df118356ba",
  });
  const [datum_day] = useState({ startdate: moment().subtract(6, "days").toDate(), enddate: new Date() });
  const [datum_month] = useState({ startdate: moment().subtract(12, "months").toDate(), enddate: new Date() });
  const [gemiddelde, Setgemiddelde] = useState(0);
  const [max, Setmax] = useState(0);
  const [fases, Setfases] = useState([0, 0, 0]);
  const [standdag, setStandDag] = useState(0);
  const [loaddata_dag, setLoaddata_dag] = useState(false);
  const [loaddata_maand, setLoaddata_maand] = useState(false);
  const [loaddata_uur, setLoaddata_uur] = useState(false);
  const [vorigegemiddelde, SetVorigegemiddelde] = useState(0);
  const [standperiode, setStandperiode] = useState(0);
  const [standbeginperiode, setStandbeginperiode] = useState(0);
  useEffect(() => {
    getvals();
  }, [datum, datum_hour]);

  function getvals() {
    props.getsensorelectra(id, { ...datum, vorigperiode }).then((data) => {
      if (data.results) {
        Setload(true);
        Setgemiddelde(data.results.data.map((element) => element.value).reduce((a, b) => a + b, 0) / data.results.data.length);
        Setmax(Math.max(...data.results.data.map((o) => o.value)));
        gemfases(data.results);
        Setgemiddelde(data.results.data.map(element => element.value).reduce((a, b) => a + b, 0) / data.results.data.length / data.results.aantaluur);
        SetVorigegemiddelde(data.results.vorigeperoide.map(element => element.value).reduce((a, b) => a + b, 0) / data.results.vorigeperoide.length / data.results.aantaluur);
        setStandbeginperiode(data.results.vorigeperoide && data.results.vorigeperoide.length > 0 ? data.results.vorigeperoide[0].value : 0);
      } else {
        props.fixmessage("warning", "Er is een fout opgtreden bij het laden van de sensoren");
      }
    });
    getvals_dag();
  }
  function getvals_dag() {
    setLoaddata_dag(false);
    props.getsensorelectra_dag(id, datum_day).then((data) => {
      if (!data.results) {
        props.fixmessage("warning", "Er is een fout opgtreden bij het laden van de sensoren");
      } else {
        setLoaddata_dag(true);
      }
    });
    getvals_maand();
  }
  function getvals_maand() {
    setLoaddata_maand(false);
    props.getsensorelectra_maand(id, datum_month).then((data) => {
      if (!data.results) {
        props.fixmessage("warning", "Er is een fout opgtreden bij het laden van de sensoren");
      } else {
        setLoaddata_maand(true);
      }
      getvals_uur();
    });
  }
  function getvals_uur() {
    setLoaddata_uur(false);
    props.getsensorelectra_uur(id, datum_hour).then((data) => {
      if (!data.results) {
        props.fixmessage("warning", "Er is een fout opgtreden bij het laden van de sensoren");
      } else {
        if (data.results.data && data.results.data[0]) {
          var totaal = data.results.data.reduce((prev, curr) => prev + curr.value, 0);
        }
        setStandDag((((totaal / 1000) * 690) / 1000).toFixed(2));
        setLoaddata_uur(true);
      }
    });
  }

  function changedata(data) {
    if (data.enddate && data.startdate) {
      const timer = setTimeout(() => {
        var duration = moment.duration(moment(data.enddate).diff(moment(data.startdate)));
        var hours = duration.asHours();
        Setvorigperiode({
          startdate: moment(data.startdate)
            .subtract(hours + 3, "hours")
            .toDate(),
          enddate: moment(data.startdate).subtract(-1, "hours").toDate(),
        });
        Setdatum(data);
      }, 100);
    }
  }

  function gemfases(data) {
    var val = [];
    val[0] =
      data.data
        .map((element) => {
          if (element.fases.Current1) {
            return element.fases.Current1;
          } else return null;
        })
        .reduce((a, b) => a + b, 0) / data.data.length;
    val[1] =
      data.data
        .map((element) => {
          if (element.fases.Current2) {
            return element.fases.Current2;
          } else return null;
        })
        .reduce((a, b) => a + b, 0) / data.data.length;
    val[2] =
      data.data
        .map((element) => {
          if (element.fases.Current3) {
            return element.fases.Current3;
          } else return null;
        })
        .reduce((a, b) => a + b, 0) / data.data.length;
    Setfases(val);
  }

  function getkwh(data) {
    var kwh = 0;
    if (data && data && data.Device === "R718N3") {
      kwh = ((data.value / 1000) * 690) / 1000;
    }
    return kwh.toFixed(2);
  }

  const { sensor } = props;
  function battery(value) {
    if (value && value && value.Volt) {
      if (value.Volt >= 3.6) {
        return <i className="fas fa-battery-full green"></i>;
      } else {
        return <i className="fas fa-battery-quarter red"></i>;
      }
    }
  }

  function luodate(data) {
    if (data[0] && data[0].datum) {
      return moment(data[0].datum).fromNow();
    } else {
      return null;
    }
  }
  function changedag(data) {
    Setdatum_hour({
      startdate: moment(data.datum.format()).subtract(1, "days").toDate(),
      enddate: data.datum.format(),
      temp_id: "eui-a84041df118356ba",
    });
  }

  return (
    <div className="box box-default show-elecra">
      <div className="box-header with-border">
        <h3 className="box-title"> {sensor.item.naam}</h3>
        <div className="box-tools pull-right">
        <Voorspellen sensor={sensor.item}  sensor_id={sensor.item._id} />
          <Link className="btn btn-warning btn-sm float-right" role="group" to={"/sensoren/sensor/edit/" + sensor.item._id}>
            Wijzigen
          </Link>
        </div>
      </div>
      <div className="box box-default">
        <div className="box-header with-border">
          <FilterSensor changedata={changedata} gemiddelde={gemiddelde} max={max} fases={fases} standperiode={standperiode} standbeginperiode={standbeginperiode} vorigegemiddelde={vorigegemiddelde} uren={sensor.item.uren} />
        </div>
      </div>
      <div className="col-md-12 no-float">
        <div className=" box-primary">
          <div className="box-body" />
          {load && (
            <div className="row p-0">
              <div className="col-12 col-md-4 col-lg-2 p-0">
                <div className="p-4">
                  <span className="valuekwh"> {getkwh(sensor.item.data[0])} KwH</span>

                  <div className="details">Laatste meting {luodate(sensor.item.data)}</div>
                </div>
              </div>
              <div className="col-12 col-md-8 col-lg-10 p-0">
                <LineGraph sensor={sensor.item} kleur="#D96A29" />
              </div>
              <div className="col-12">
                <div className="p2">Batterij: {battery(sensor.item.data[0])} </div>
              </div>
              <div className="col-12 col-md-6 col-lg-6 p-0">
                <h3 className="p-4">Metingen per dag</h3>
                {loaddata_dag && <BarGraph sensor={sensor.dag} kleur="#B1BF41" type="dag" />}
              </div>
              <div className="col-12 col-md-6 col-lg-6 p-0">
                <h3 className="p-4">Metingen per maand</h3>
                {loaddata_maand && <BarGraph sensor={sensor.maand} kleur="#D96A29" type="maand" />}
              </div>
              <div className="col-12 col-md-12 col-lg-12">
                <div className="row">
                  <div className="col-12 col-md-8 col-lg-8">
                    <div className="box-titel">
                      <h3 className="">Metingen 24 uur ({standdag} KwH)</h3>
                    </div>
                  </div>
                  <div className="col-8 col-md-4 col-lg-4 offset-4 offset-md-0">
                    <KiesDate changedata={changedag} />
                  </div>
                </div>

                <BarGraph sensor={sensor.uur} kleur="#D96A29" type="uur" />
              </div>
           
              <div className="col-12">
                <h3 className="p-4">Gegevens tabel metingen</h3>
                <Tabel sensor={sensor.item} />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return { sensor: state.sensoren };
}

export default connect(mapStateToProps, { getsensorelectra, fixmessage, getsensorelectra_dag, getsensorelectra_maand, getsensorelectra_uur })(SensorItemElectra);
