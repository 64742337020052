export const SET_CMSS = "SET_CMSS";
export const ADD_CMSS = "ADD_CMSS";
export const CMS_ERROR = "CMS_ERROR";
export const SET_CMS = "SET_CMS";
export const SET_CMS_MEDIA = "SET_CMS_MEDIA";
export const GET_CMS_MEDIA = "GET_CMS_MEDIA";
export const SET_CMS_COMPONENT = "SET_CMS_COMPONENT";
export const GET_CMS_COMPONENT = "GET_CMS_COMPONENT";
export const SET_CMS_TEMPLATE = "SET_CMS_TEMPLATE";
export const GET_CMS_TEMPLATE = "GET_CMS_TEMPLATE";
export const SET_CMS_SETTINGS = "SET_CMS_SETTINGS";
export const SET_CMS_PAGE = "SET_CMS_PAGE";
export const GET_CMS_PAGE = "GET_CMS_PAGE";

function handleResponse(response) {
  if (response.ok) {
    return response.json();
  } else {
    let error = new Error(response.statusText);
    error.response = response.statusText;
    throw error;
  }
}

export function settings(results) {
  return {
    type: SET_CMS_SETTINGS,
    results,
  };
}

export function set(results) {
  return {
    type: SET_CMSS,
    results,
  };
}

export function setmedia(results) {
  return {
    type: SET_CMS_MEDIA,
    results,
  };
}
export function getmedia(results) {
  return {
    type: GET_CMS_MEDIA,
    results,
  };
}

export function setcomponent(results) {
  return {
    type: SET_CMS_COMPONENT,
    results,
  };
}

export function getcomponent(results) {
  return {
    type: GET_CMS_COMPONENT,
    results,
  };
}

export function settemplate(results) {
  return {
    type: SET_CMS_TEMPLATE,
    results,
  };
}

export function gettemplate(results) {
  return {
    type: GET_CMS_TEMPLATE,
    results,
  };
}

export function setpages(results) {
  return {
    type: SET_CMS_PAGE,
    results,
  };
}

export function getpages(results) {
  return {
    type: GET_CMS_PAGE,
    results,
  };
}

export function setsettings(results) {
  return {
    type: SET_CMS_SETTINGS,
    results,
  };
}

export function setone(results) {
  return {
    type: SET_CMS,
    results,
  };
}
export function add(results) {
  return {
    type: SET_CMS,
    results,
  };
}
export function itemError(error) {
  return {
    type: CMS_ERROR,
    payload: error,
  };
}

export function savesettings(data) {
  return (dispatch) => {
    return fetch("/api/admin/cms/settings", {
      method: "post",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("token"),
      },
    })
      .then(handleResponse)
      .then((results) => {
        dispatch(add(results));
        return true;
      })
      .catch((err) => {
        return dispatch(itemError("Er is een fout opgetreden bij het insert database"));
      });
  };
}

export function fetchsettings() {
  return (dispatch) => {
    return fetch("/api/admin/cms/settings", {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    })
      .then(handleResponse)
      .then((results) => {
        dispatch(setsettings(results.results));
        return true;
      })
      .catch((err) => {
        return dispatch(itemError("Er is een fout opgetreden bij het ophalen van de cmss"));
      });
  };
}

export function findmediaindir() {
  return (dispatch) => {
    return fetch("/api/admin/cms/settings/media", {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    })
      .then(handleResponse)
      .then((results) => {
        return results.results;
      })
      .catch((err) => {
        return dispatch(itemError("Er is een fout opgetreden bij het ophalen van de cmss"));
      });
  };
}


export function findmedia({ page, limit, sort, order }) {
  return (dispatch) => {
    const queryString = new URLSearchParams({ page, limit, sort, order }).toString();
    const url = `/api/admin/cms/media?${queryString}`;
    return fetch(url, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    })
      .then(handleResponse)
      .then((results) => {
        dispatch(setmedia(results));
        return true;
      })
      .catch((err) => {
        return dispatch(itemError("Er is een fout opgetreden bij het ophalen van de cmss"));
      });
  };
}
export function fetchmedia(id) {
  return (dispatch) => {
    const url = `/api/admin/cms/media/${id}`;
    return fetch(url, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    })
      .then(handleResponse)
      .then((results) => {
        dispatch(getmedia(results.results));
        return true;
      })
      .catch((err) => {
        return dispatch(itemError("Er is een fout opgetreden bij het ophalen van de cmss"));
      });
}
}

export function uploadmedia(data) {
  return async (dispatch) => {
    var formData = new FormData();
    formData.append("file", data);
    try {
      const response = await fetch("/api/admin/cms/media/upload", {
        method: "post",
        body: formData,
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      const responseData = await handleResponse(response);
      return responseData.images;
    } catch (err) {
      return dispatch(itemError("Er is een fout opgetreden bij het uploaden van de bestanden"));
    }
  };
}

export function saveComponent(data) {
  return (dispatch) => {
    return fetch("/api/admin/cms/component", {
      method: "post",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("token"),
      },
    })
      .then(handleResponse)
      .then((results) => {
        return dispatch(getcomponent(results));
      })
      .catch((err) => {
        return dispatch(itemError("Er is een fout opgetreden bij het insert database"));
      });
  };
}

export function fetchcomponents() {
  return (dispatch) => {
    return fetch("/api/admin/cms/component", {
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("token"),
      },
    })
      .then(handleResponse)
      .then((results) => {
        return dispatch(setcomponent(results.results));
      })
      .catch((err) => {
        return dispatch(itemError("Er is een fout opgetreden bij het insert database"));
      });
  };
}
export function fetchOnecomponents(id) {
  return (dispatch) => {
    return fetch("/api/admin/cms/component/" + id, {
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("token"),
      },
    })
      .then(handleResponse)
      .then((results) => {
        return dispatch(getcomponent(results.results));
      })
      .catch((err) => {
        return dispatch(itemError("Er is een fout opgetreden bij het insert database"));
      });
  };
}

export function updateComponent(id, data) {
  return (dispatch) => {
    return fetch("/api/admin/cms/component/" + id, {
      method: "put",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("token"),
      },
    })
      .then(handleResponse)
      .then((results) => {
        return dispatch(getcomponent(results.results));
      })
      .catch((err) => {
        return dispatch(itemError("Er is een fout opgetreden bij het insert database"));
      });
  };
}

export function SavePageTemplate(data) {
  return (dispatch) => {
    return fetch("/api/admin/cms/pagetemplate", {
      method: "post",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("token"),
      },
    })
      .then(handleResponse)
      .then((results) => {
        return dispatch(gettemplate(results.results));
      })
      .catch((err) => {
        return dispatch(itemError("Er is een fout opgetreden bij het insert database"));
      });
  };
}

export function fetchPageTemplate() {
  return (dispatch) => {
    return fetch("/api/admin/cms/pagetemplate", {
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("token"),
      },
    })
      .then(handleResponse)
      .then((results) => {
        return dispatch(settemplate(results.results));
      })
      .catch((err) => {
        return dispatch(itemError("Er is een fout opgetreden bij het insert database"));
      });
  };
}

export function fetchOnePageTemplate(id) {
  return (dispatch) => {
    return fetch("/api/admin/cms/pagetemplate/" + id, {
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("token"),
      },
    })
      .then(handleResponse)
      .then((results) => {
        return dispatch(gettemplate(results.results));
      })
      .catch((err) => {
        return dispatch(itemError("Er is een fout opgetreden bij het insert database"));
      });
  };
}

export function updatePageTemplate(id, data) {
  return (dispatch) => {
    return fetch("/api/admin/cms/pagetemplate/" + id, {
      method: "put",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("token"),
      },
    })
      .then(handleResponse)
      .then((results) => {
        return dispatch(gettemplate(results.results));
      })
      .catch((err) => {
        return dispatch(itemError("Er is een fout opgetreden bij het insert database"));
      });
  };
}

export function fetchpages() {
  return (dispatch) => {
    return fetch("/api/admin/cms/pages", {
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("token"),
      },
    })
      .then(handleResponse)
      .then((results) => {
        return dispatch(setpages(results.results));
      })
      .catch((err) => {
        return dispatch(itemError("Er is een fout opgetreden bij het insert database"));
      });
  };
}

export function savepages(data) {
  return (dispatch) => {
    return fetch("/api/admin/cms/pages", {
      method: "post",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("token"),
      },
    })
      .then(handleResponse)
      .then((results) => {
        return dispatch(getpages(results.results));
      })
      .catch((err) => {
        return dispatch(itemError("Er is een fout opgetreden bij het insert database"));
      });
  };
}

export function getonepages(id) {
  return (dispatch) => {
    return fetch("/api/admin/cms/pages/" + id, {
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("token"),
      },
    })
      .then(handleResponse)
      .then((results) => {
        return dispatch(getpages(results.results));
      })
      .catch((err) => {
        return dispatch(itemError("Er is een fout opgetreden bij het insert database"));
      });
  };
}

export function getonepagesbyslug(slug) {
  return (dispatch) => {
    return fetch("/api/admin/cms/pages/slug", {
      method: "post",
      body: JSON.stringify({ slug }),
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("token"),
      },
    })
      .then(handleResponse)
      .then((results) => {
        return dispatch(getpages(results.results));
      })
      .catch((err) => {
        return dispatch(itemError("Er is een fout opgetreden bij het insert database"));
      });
  };
}

export function updatepages(id, data) {
  return (dispatch) => {
    return fetch("/api/admin/cms/pages/" + id, {
      method: "put",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("token"),
      },
    })
      .then(handleResponse)
      .then((results) => {
        return dispatch(getpages(results.results));
      })
      .catch((err) => {
        return dispatch(itemError("Er is een fout opgetreden bij het insert database"));
      });
  };
}

export function vertalen(data) {
  return (dispatch) => {
    return fetch("/api/admin/cms/pages/vertalen", {
      method: "post",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("token"),
      },
    })
      .then(handleResponse)
      .then((results) => {
        return results.results;
      })
      .catch((err) => {
        return dispatch(itemError("Er is een fout opgetreden bij het insert database"));
      });
  };
}
