import React from "react";
import { Field, Form } from "react-final-form";
import { connect } from "react-redux";
import { required } from "redux-form-validators";
import Wysiwyg from "../../../Components/Forms/wysiwyg";
import NDTRCTags from "../../../Components/Forms/ndtrctags";
import FooterForm from "../../../Components/Lib/FooterForm";
import Autosuggest from "../../../Components/Forms/autocomplete";
import switcbutton from "../../../Components/Forms/switch";
import { renderTextField, renderField, renderAField, renderselectsimple, renderAFieldDisabled } from "../../../Components/Forms/renders";
class SyncValidationForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: {},
      isaevent: false,
    };
  }
  isevent = (value) => {
    if (value.target.value === "EVENEMENT") {
      this.setState({ isaevent: true });
    } else {
      this.setState({ isaevent: false });
    }
  };
  componentDidMount() {
    if (this.props.item.metadata.entitytype === "EVENEMENT") {
      this.setState({ isaevent: true });
    }
  }
  showbedrijfevet = () => {
    if (this.state.isaevent) {
      return (
        <div>
          <Field name="subitemsgroup" type="input" component={Autosuggest} label="Kies om het item aan een locatie te koppelen" />
          <Field name="event.everyyear" type="input" component={switcbutton} label="Wordt dit evenement jaarlijks herhaald?" />
        </div>
      );
    }
  };
  render() {
    const { onSubmit, item } = this.props;

    return (
      <Form
        onSubmit={onSubmit}
        initialValues={item}
        render={({ form, handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <div className="col-sm-12">
              <h4 className="info-text">Vul hier de algemene gegevens in</h4>
            </div>
            <Field
              className="form"
              autoFocus={true}
              name="metadata.entitytype"
              validate={required({ msg: "Type item is verplicht" })}
              label="Maak eerste een keuze"
              component={renderselectsimple}
              options={{
                EVENEMENT: "Evenement",
                LOCATIE: "Locatie",
              }}
              onChange={this.isevent}
            />
            {this.showbedrijfevet()}

            <Field  name="what.title.value" validate={required({ msg: "Titel is verplicht" })} type="text" component={renderField} label="Titel" />
            <Field name="what.slug.value" type="text" component={renderAFieldDisabled} label="Slug van de titel" />
            <Field name="what.shortdescription.value" validate={required({ msg: "Beschrijving is verplicht" })} type="textarea" component={renderTextField} label="Korte Omschrijving" />
            <Field name="what.longdescription.value" type="textarea" component={Wysiwyg} label="Lange Omschrijving" className="form-control" normalize={(value) => value.toString("html")} />
            <Field name="types" validate={required({ msg: "Types zijn verplicht" })} type="text" component={NDTRCTags} label="Tags" className="form-control" />
            <FooterForm />
          </form>
        )}
      />
    );
  }
}

// You have to connect() to any Reducers that you wish to connect to yourself
SyncValidationForm = connect(
  (state) => ({
    item: state.items.item, // pull initial values from account reducer
  }) // bind account loading action creator
)(SyncValidationForm);

export default SyncValidationForm;
