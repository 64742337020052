import React, { useEffect, useState } from "react";

import Rodal from "rodal";
import Uploader from "../../../Media/Components/uploader";
import { findmedia } from "../../../Reducers/actions";
import { message } from "../../../../../Reducers/loading/actions";
import { connect } from "react-redux";

function MediaForm({ findmedia, media, input, label, type, meta: { touched, error, warning } }) {
  const [sortBy, setSortBy] = useState("id"); // Begin met standaard sorteerwaarde
  const [sortOrder, setSortOrder] = useState("DESC"); // Begin met standaard sorteervolgorde
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 12; // Aantal items per pagina

  useEffect(() => {
    findmedia({
      page: currentPage,
      limit: itemsPerPage,
      sort: sortBy,
      order: sortOrder,
    }).then((res) => {
      setLoading(true);
    });
  }, [currentPage, sortBy, sortOrder]);

  useEffect(() => {
    if (input.value) {
      setSelected(input.value);
    }
  }, [input.value]);

  const handleSortChange = (event) => {
    const selectedSortBy = event.target.value;
    setSortBy(selectedSortBy);
    // Reset de sorteervolgorde naar de standaardwaarde wanneer de sorteeroptie verandert
    setSortOrder("DESC");
  };

  const handleSortOrderChange = (event) => {
    const selectedSortOrder = event.target.value;
    setSortOrder(selectedSortOrder);
  };

  const selecteerFoto = (item) => {
    setSelected(item);
    input.onChange(item);
    setVisible(false);
  };
  
  const remove = () => {
    setSelected({});
    input.onChange({});
  }

  return (
    <div className="media">
      <label>{label}</label>
      <div>
        <div className="mediaSelect">
          <button type="button" className="btn btn-block btn-success btn-sm " onClick={() => setVisible(true)}>
            <i className="fa fa-plus"></i> Selecteer bestand
          </button>
          {touched && error && <span className="text-danger">{error}</span>}

          <div className="box box-solid">
            <div className="media_item">{selected && selected.thumbnail && <img src={selected && selected.thumbnail ? selected.thumbnail.small : ""} className="img-responsive custom-img" />}</div>
            {selected && selected.thumbnail && (
            <div className="box-footer">
              <button type="button" className="btn btn-block btn-danger btn-sm " onClick={() => remove()}>Verwijder foto</button>
              </div>
            )}
          </div>
        </div>
      </div>
      <Rodal visible={visible} onClose={() => setVisible(false)} className="rodal-lg">
        <div className="box box-default settings">
          <div className="box-header with-border">
            <h3 className="box-title"> Media bestanden</h3>
            <div className="box-tools pull-right d-flex">
              <div className="dropdown me-3">
                <button className="btn btn-secondary dropdown-toggle" type="button" id="sortDropdown" data-bs-toggle="dropdown" aria-expanded="false">
                  Sorteer op: {sortBy}
                </button>
                <ul className="dropdown-menu" aria-labelledby="sortDropdown">
                  <li>
                    <button type="button" className="dropdown-item" onClick={handleSortChange} value="created">
                      Aangemaakt op
                    </button>
                  </li>
                  <li>
                    <button type="button" className="dropdown-item" onClick={handleSortChange} value="name">
                      Naam
                    </button>
                  </li>
                  <li>
                    <button type="button" className="dropdown-item" onClick={handleSortChange} value="size">
                      Groote
                    </button>
                  </li>
                </ul>
              </div>
              <div className="dropdown me-3">
                <button className="btn btn-secondary dropdown-toggle" type="button" id="orderDropdown" data-bs-toggle="dropdown" aria-expanded="false">
                  Volgorde: {sortOrder}
                </button>
                <ul className="dropdown-menu" aria-labelledby="orderDropdown">
                  <li>
                    <button className="dropdown-item" onClick={handleSortOrderChange} value="DESC" type="button">
                      Aflopend
                    </button>
                  </li>
                  <li>
                    <button className="dropdown-item" onClick={handleSortOrderChange} value="ASC"type="button">
                      Oplopend
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="box-body">
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-12">
                  <div className="box box-info">
                    <div className="box-header with-border">
                      <h3 className="box-title">Bestanden</h3>
                    </div>
                    <div className="box-body">
                      <div className="row">
                        {loading &&
                          media.results.map((item, index) => (
                            <div className="col-lg-3 col-md-4 col-sm-6 col-xs-12 media_item" key={index} onClick={() => selecteerFoto(item)}>
                              <div className="box box-solid">
                                <div className="box-body">
                                  <div className="row">
                                    <div className="col-lg-12 text-center">
                                      <div className="col-lg-12 text-center">
                                        <img src={item.thumbnail ? item.thumbnail.small : item.image} className="img-responsive custom-img" />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="box-footer">
                                  <div>
                                    {item.name} <br />
                                    {item.width} X {item.height} <br />
                                  </div>
                                  <div className="box-tools">
                                    <h5>
                                      {" "}
                                      <span className="badge badge-secondary">{item.fileType}</span>
                                    </h5>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}
                      </div>
                    </div>
                  </div>
                  <div className="box-footer text-center">
                    <nav aria-label="Paginering">
                      <ul className="pagination">
                        {Array.from({ length: media.totalPages }).map((_, index) => (
                          <li key={index} className={`page-item ${media.currentPage === index + 1 ? "active" : ""}`}>
                            <button type="button" className="page-link" onClick={() => setCurrentPage(index + 1)}>
                              {index + 1}
                            </button>
                          </li>
                        ))}
                      </ul>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Rodal>
    </div>
  );
}

function mapStateToProps(state) {
  return { media: state.cms.media.items };
}

export default connect(mapStateToProps, {
  findmedia,
  message,
})(MediaForm);
