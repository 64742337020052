import { SET_CMSS, ADD_CMSS, CMS_ERROR, SET_CMS, SET_CMS_SETTINGS, SET_CMS_MEDIA, SET_CMS_COMPONENT, GET_CMS_COMPONENT, GET_CMS_TEMPLATE, SET_CMS_TEMPLATE, SET_CMS_PAGE, GET_CMS_PAGE, GET_CMS_MEDIA } from "./actions";
export default function cms(
  state = {
    item: [],
    items: [],
    settings: {},
    counters: [],
    media: {
      items: [],
      item: {},
    },
    components: {
      items: [],
      item: {},
    },
    templates: {
      items: [],
      item: {},
    },
    pages: {
      items: [],
      item: {},
    },
  },
  action = {}
) {
  switch (action.type) {
    case ADD_CMSS:
      return { ...state, items: [...state.itens, action.results] };
    case SET_CMS:
      return { ...state, item: action.results };
    case SET_CMS_MEDIA:
      return {
        ...state,
        media: {
          ...state.components,
          items: action.results, // Hier wordt de items in components bijgewerkt
        },
      };
      case GET_CMS_MEDIA:
        return {
          ...state,
          media: {
            ...state.components,
            item: action.results, // Hier wordt de items in components bijgewerkt
          },
        };
    case SET_CMS_SETTINGS:
      return { ...state, settings: action.results };
    case CMS_ERROR:
      return { ...state, error: action.payload };
    case SET_CMSS:
      return { ...state, items: action.results };
    case SET_CMS_COMPONENT:
      return {
        ...state,
        components: {
          ...state.components,
          items: action.results, // Hier wordt de items in components bijgewerkt
        },
      };

    case GET_CMS_COMPONENT:
      return {
        ...state,
        components: {
          ...state.components,
          item: action.results, // Hier wordt het item in components bijgewerkt
        },
      };
    case SET_CMS_TEMPLATE:
      return {
        ...state,
        templates: {
          ...state.templates,
          items: action.results, // Hier wordt de items in components bijgewerkt
        },
      };

    case GET_CMS_TEMPLATE:
      return {
        ...state,
        templates: {
          ...state.templates,
          item: action.results, // Hier wordt het item in components bijgewerkt
        },
      };
    case SET_CMS_PAGE:
      return {
        ...state,
        pages: {
          ...state.pages,
          items: action.results, // Hier wordt de items in components bijgewerkt
        },
      };

    case GET_CMS_PAGE:
      return {
        ...state,
        pages: {
          ...state.pages,
          item: action.results, // Hier wordt het item in components bijgewerkt
        },
      };

    default:
      return state;
  }
}
