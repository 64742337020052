import React from "react";
import { Routes, Route } from "react-router-dom";
import EventPage from './index';

const EventRouter = () => {
  return (
    <Routes>
      <Route path='/' element={<EventPage />} />
    </Routes>
  );
};

export default EventRouter;
